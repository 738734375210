import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaRegHandPointRight } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { editDataFromList, updateDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';
import { GiMushroomGills } from 'react-icons/gi';

const initialState = {
    process_name: '',
    process_mobile: '',
}

const EditServiceProvider = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        getSingleProcess(slug)
    }, [slug])

    
    const getSingleProcess = (slug) => {
        editDataFromList(`sub-admin-get-single-service-provider-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.servicedata])
            }
        })
    }
    const handleInputs = async (e) => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            updateDataAPI(`sub-admin-update-service-provider-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-subadmin-service-provider");
                    toast.success("Process has been updated successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Process already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUB ADMIN::Edit Process</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <GiMushroomGills className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">Edit Process</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleUpdateSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationName">
                                        <Form.Label>Provider Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Provider Name"
                                            required
                                            name="service_provider_name"
                                            onChange={handleInputs}
                                            value={values?.service_provider_name}                                       
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid provider name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationMobile">
                                        <Form.Label>Provider URL</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Provider URL"
                                            required
                                            name="service_provider_url"
                                            onChange={handleInputs}
                                            value={values?.service_provider_url}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid provider url
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Submit</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditServiceProvider