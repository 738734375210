import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaMobile, FaRegHandPointRight, FaRegUser } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaHouseDamage } from 'react-icons/fa'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { editDataFromList, updateDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';
import { MdEmail } from 'react-icons/md';
import { Spinner } from 'react-bootstrap';

const initialState = {
    chanel_name: '',
}

const EditDispatch = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getSingleDispatch(slug)
    }, [slug])

    
    const getSingleDispatch = (slug) => {
        editDataFromList(`sub-admin-get-single-dispatch-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.dispatchdata])
            }
        })
    }

    const handleInputs = async (e) => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdateChanel = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setIsLoading(false);
            event.stopPropagation();
        }
        else {
            updateDataAPI(`sub-admin-update-dispatch-data/${slug}`, values).then((res) => {
                setIsLoading(false);
                if (res.data.status === 1) {
                    navigate("/all-subadmin-dispatch");
                    toast.success("Dispatch has been updated successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Dispatch already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUB ADMIN::Edit Dispatch</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaHouseDamage className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">Edit Dispatch</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleUpdateChanel}>
                                <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationName">
                                        <Form.Label>Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaRegUser /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="dispatch_name"
                                                onChange={handleInputs}
                                                value={values?.dispatch_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationMobileName">
                                        <Form.Label>Mobile</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaMobile /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter mobile number"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="dispatch_mobile"
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                onChange={handleInputs}
                                                value={values?.dispatch_mobile}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid mobile number.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmailName">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><MdEmail /></InputGroup.Text>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email address"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="dispatch_email"
                                                onChange={handleInputs}
                                                value={values?.dispatch_email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid email address.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    
                                </Row>
                                {isLoading ?
                                    <Button className="btn-dark" type="submit" disabled={true}>
                                        <Spinner animation="border"
                                            size="sm"
                                            role="status"
                                            variant="success"
                                        />
                                        Wait Updating...
                                    </Button>
                                    :
                                    <Button type="submit">Update</Button>
                                }
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditDispatch