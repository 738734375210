import React, { useEffect, useState } from 'react'
import logo from "../assets/logo.webp"
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AiOutlineDashboard, AiOutlineLogout, AiOutlineMenu, AiOutlineSetting, AiFillCloseCircle, AiFillProfile } from "react-icons/ai";
import { getSingleDataFromDatabase } from '../utils/api';
import { BASEURLS } from '../utils/BaseUrl';
import { FaLanguage } from 'react-icons/fa';
import { GiMushroomGills } from 'react-icons/gi';
import { RiChatFollowUpFill, RiTeamFill } from 'react-icons/ri';
import { SiInstatus } from 'react-icons/si';
import { GrServices } from 'react-icons/gr';

const Sidebar = () => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const subadminid = localStorage.getItem("subadmindata");
    const [subadmin, setSubadmin] = useState([]);

    const openMobileMenu = () => {
        if (mobileMenu) {
            setMobileMenu(false)
        }
        else {
            setMobileMenu(true)
        }
    }

    useEffect(() => {
        getSingleSubadminData(subadminid);
    }, [subadminid]);

    const getSingleSubadminData = (subadminid) => {
        getSingleDataFromDatabase(`sub-admin-get-single-data/${subadminid}`).then((res) => {
            if (res.data.status === 1) {
                setSubadmin(...[res.data.subadmindata]);
            }
        })
    }
    const navigate = useNavigate();
    function logout() {
        localStorage.clear();
        navigate("/");
    }
    return (
        <div className="wrapper">
            <div className="topbarSection">
                <div className="topbar">
                    <div className="mobile-toggle-menu">
                        <Link to="/dashboard">
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <nav className="navbar navbar-expand">
                        <div className="user-box dropdown desktopDisplay">
                            <button className="d-flex align-items-center nav-link" role="button">
                                <div className="username">
                                    <div className="userimg">
                                        {(() => {
                                            if (subadmin) {
                                                return (
                                                    <img className="rounded-circle" src={BASEURLS(subadmin?.subadmin_foto)} alt={subadmin?.subadmin_foto} />
                                                )
                                            }
                                            else {
                                                return (
                                                    <img className="rounded-circle" src={BASEURLS(subadmin?.subadmin_foto)} alt={subadmin?.subadmin_foto} />
                                                )
                                            }
                                        })()}

                                    </div>
                                    <div className="user-info ps-3">
                                        <p className="user-name mb-0 dropdown-toggle" data-bs-toggle="dropdown">{subadmin?.subadmin_name}</p>
                                        <p className="designattion mb-0">Subadmin Panel</p>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="/my-profile"><AiOutlineSetting />Settings</Link>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" onClick={logout}><AiOutlineLogout /> LogOut</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="mobileDisplay">
                            <button onClick={openMobileMenu}>
                                {mobileMenu ?
                                    (
                                        <AiFillCloseCircle className="mobileMenuIcones" />
                                    )
                                    :
                                    (
                                        <AiOutlineMenu className="mobileMenuIcones" />
                                    )
                                }
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="sidebarWrapper desktopDisplay">
                <div className="simplebar-mask">
                    <div className="simplebar-offset">
                        <div className="simplebar-content-wrapper">
                            <div className="simplebar-content mm-active">
                                <ul className="metismenu mm-show" id="menu">
                                    <li>
                                        <NavLink activeclassname="active" to="/dashboard">
                                            <AiOutlineDashboard />
                                            <span>Dashboard</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-chanel">
                                            <AiFillProfile />
                                            <span>All Chanel</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-language">
                                            <FaLanguage />
                                            <span>All Language</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-problem">
                                            <GiMushroomGills />
                                            <span>All Problem</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-dispatch">
                                            <RiTeamFill />
                                            <span>Dispatch Team</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-ppc-team">
                                            <RiTeamFill />
                                            <span>PPC Team</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-service-provider">
                                            <GrServices />
                                            <span>All Service Provider</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-followup">
                                            <RiChatFollowUpFill />
                                            <span>All Call Follow Up</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-process">
                                            <RiChatFollowUpFill />
                                            <span>All Process Number</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-order-status">
                                            <SiInstatus />
                                            <span>All Order Status</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-delivery-status">
                                            <SiInstatus />
                                            <span>All Delivery Status</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-subadmin-delivery-patner">
                                            <SiInstatus />
                                            <span>All Delivery Patner</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/my-profile">
                                            <AiFillProfile />
                                            <span>My Profile</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <button onClick={logout}>
                                            <AiOutlineLogout />
                                            <span>Log Out</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${mobileMenu ? "" : "mobileMenuActive"}`}>
                <div className="mobilesidebarWrapper mobileDisplay">
                    <div className="simplebar-mask">
                        <div className="simplebar-offset">
                            <div className="simplebar-content-wrapper">
                                <div className="simplebar-content mm-active">
                                    <ul className="metismenu mm-show" id="menu">
                                        <li>
                                            <NavLink activeclassname="active" to="/dashboard">
                                                <AiOutlineDashboard />
                                                <span>Dashboard</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink onClick={logout}>
                                                <AiOutlineLogout />
                                                <span>LogOut</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
