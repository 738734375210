import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MyProfile from "./pages/MyProfile";
import 'react-loading-skeleton/dist/skeleton.css'
import MobileOTP from "./pages/MobileOTP";
import Chanel from "./pages/chanel/Chanel";
import AddChanel from "./pages/chanel/AddChanel";
import EditChanel from "./pages/chanel/EditChanel";
import Language from "./pages/language/Language";
import AddLanguage from "./pages/language/AddLanguage";
import EditLanguage from "./pages/language/EditLanguage";
import Problem from "./pages/problem/Problem";
import AddProblem from "./pages/problem/AddProblem";
import EditProblem from "./pages/problem/EditProblem";
import SubProblem from "./pages/problem/SubProblem";
import PPCTeam from "./pages/ppc/PPCTeam";
import AddPPCTeam from "./pages/ppc/AddPPCTeam";
import EditPPCTeam from "./pages/ppc/EditPPCTeam";
import FollowUp from "./pages/callfollowup/FollowUp";
import AddFollowUp from "./pages/callfollowup/AddFollowUp";
import EditFollowUp from "./pages/callfollowup/EditFollowUp";
import ProcessNumber from "./pages/process/ProcessNumber";
import AddProcessNumber from "./pages/process/AddProcessNumber";
import EditProcessNumber from "./pages/process/EditProcessNumber";
import OrderStatus from "./pages/orderstatus/OrderStatus";
import AddOrderStatus from "./pages/orderstatus/AddOrderStatus";
import EditOrderStatus from "./pages/orderstatus/EditOrderStatus";
import DeliveryStatus from "./pages/deliverystatus/DeliveryStatus";
import AddDeliveryStatus from "./pages/deliverystatus/AddDeliveryStatus";
import EditDeliveryStatus from "./pages/deliverystatus/EditDeliveryStatus";
import DeliveryPatner from "./pages/deliverypatner/DeliveryPatner";
import AddDeliveryPatner from "./pages/deliverypatner/AddDeliveryPatner";
import EditDeliveryPatner from "./pages/deliverypatner/EditDeliveryPatner";
import Dispatch from "./pages/dispatch/Dispatch";
import AddDispatch from "./pages/dispatch/AddDispatch";
import EditDispatch from "./pages/dispatch/EditDispatch";
import DisplayProcess from "./pages/ppc/DisplayProcess";
import SkillDetails from "./pages/ppc/SkillDetails";
import ServiceProvider from "./pages/service/ServiceProvider";
import AddServiceProvider from "./pages/service/AddServiceProvider";
import EditServiceProvider from "./pages/service/EditServiceProvider";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mobile-otp" element={<MobileOTP />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/all-subadmin-chanel" element={<Chanel />} />
          <Route path="/add-subadmin-chanel" element={<AddChanel />} />
          <Route path="/edit-subadmin-chanel/:slug" element={<EditChanel />} />
          <Route path="/all-subadmin-language" element={<Language />} />
          <Route path="/add-subadmin-language" element={<AddLanguage />} />
          <Route path="/edit-subadmin-language/:slug" element={<EditLanguage />} />
          <Route path="/all-subadmin-problem" element={<Problem />} />
          <Route path="/add-subadmin-problem" element={<AddProblem />} />
          <Route path="/edit-subadmin-problem/:slug" element={<EditProblem />} />
          <Route path="/all-subadmin-sub-problem/:slug" element={<SubProblem />} />
          <Route path="/all-subadmin-ppc-team" element={<PPCTeam />} />
          <Route path="/add-subadmin-ppc-team" element={<AddPPCTeam />} />
          <Route path="/edit-subadmin-ppc-team/:slug" element={<EditPPCTeam />} />
          <Route path="/display-subadmin-process-details/:slug" element={<DisplayProcess />} />
          <Route path="/display-subadmin-skill-details/:slug" element={<SkillDetails />} />
          <Route path="/all-subadmin-followup" element={<FollowUp />} />
          <Route path="/add-subadmin-followup" element={<AddFollowUp />} />
          <Route path="/edit-subadmin-followup/:slug" element={<EditFollowUp />} />
          <Route path="/all-subadmin-process" element={<ProcessNumber />} />
          <Route path="/add-subadmin-process" element={<AddProcessNumber />} />
          <Route path="/edit-subadmin-process/:slug" element={<EditProcessNumber />} />
          <Route path="/all-subadmin-order-status" element={<OrderStatus />} />
          <Route path="/add-subadmin-order-status" element={<AddOrderStatus />} />
          <Route path="/edit-subadmin-order-status/:slug" element={<EditOrderStatus />} />
          <Route path="/all-subadmin-delivery-status" element={<DeliveryStatus />} />
          <Route path="/add-subadmin-delivery-status" element={<AddDeliveryStatus />} />
          <Route path="/edit-subadmin-delivery-status/:slug" element={<EditDeliveryStatus />} />
          <Route path="/all-subadmin-delivery-patner" element={<DeliveryPatner />} />
          <Route path="/add-subadmin-delivery-patner" element={<AddDeliveryPatner />} />
          <Route path="/edit-subadmin-delivery-patner/:slug" element={<EditDeliveryPatner />} />
          <Route path="/all-subadmin-dispatch" element={<Dispatch />} />
          <Route path="/add-subadmin-dispatch" element={<AddDispatch />} />
          <Route path="/edit-subadmin-dispatch/:slug" element={<EditDispatch />} />
          <Route path="/all-subadmin-service-provider" element={<ServiceProvider />} />
          <Route path="/add-subadmin-service-provider" element={<AddServiceProvider />} />
          <Route path="/edit-subadmin-service-provider/:slug" element={<EditServiceProvider />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
