import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaRegHandPointRight } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { editDataFromList, fetchAllDataAPI, updateDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';
import { GiMushroomGills } from 'react-icons/gi';

const initialState = {
    process_name: '',
    process_mobile: '',
    process_product: '',
    process_ppc: '',
    process_language: '',
    process_provider: '',
    process_handle_by: '',
}

const EditProcessNumber = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [validated, setValidated] = useState(false);
    const [ppc, setPPC] = useState([]);
    const [language, setLanguage] = useState([]);
    const [service, setService] = useState([]);

    useEffect(() => {
        getSingleProcess(slug)
        getPPCLanguage();
    }, [slug])

    
    const getSingleProcess = (slug) => {
        editDataFromList(`sub-admin-get-single-process-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.processdata])
            }
        })
    }
    const getPPCLanguage = () => {
        fetchAllDataAPI('sub-admin-get-ppc-language').then((res)=>{
            if(res.data.status===1){
                setPPC(...[res.data.ppc]);
                setLanguage(...[res.data.language]);
                setService(...[res.data.service]);
            }
        })
    }
    const handleInputs = async (e) => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            updateDataAPI(`sub-admin-update-process-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-subadmin-process");
                    toast.success("Process has been updated successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Process already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUB ADMIN::Edit Process</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <GiMushroomGills className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">Edit Process</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleUpdateSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationName">
                                        <Form.Label>Process Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Process Name"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="process_name"
                                            onChange={handleInputs}
                                            value={values?.process_name}                                       
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid process name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationMobile">
                                        <Form.Label>Process Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Process Number"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="process_mobile"
                                            pattern="[6789][0-9]{9}"
                                            maxLength={10}
                                            minLength={10}
                                            onChange={handleInputs}
                                            value={values?.process_mobile}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid process number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Product Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Product Name"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="process_product"
                                            onChange={handleInputs}
                                            value={values?.process_product}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid product name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Select PPC Team</Form.Label>
                                        <Form.Select required
                                            placeholder="Category Name"
                                            name="process_ppc"
                                            onChange={handleInputs}>
                                            <option value={values?.process_ppc}>{values?.ppc_name}</option>
                                            {ppc?.map((ppc, index)=>(
                                                <option key={index} value={ppc?.ppc_id}>{ppc?.ppc_name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid ppc team
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Select Language</Form.Label>
                                        <Form.Select required
                                            placeholder="Category Name"
                                            name="process_language"
                                            onChange={handleInputs}>
                                            <option value={values?.process_language}>{values?.language_name}</option>
                                            {language?.map((language, index)=>(
                                                <option key={index} value={language?.language_id}>{language?.language_name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid language
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Select Provider Name</Form.Label>
                                        <Form.Select required
                                            placeholder="Category Name"
                                            name="process_provider"
                                            onChange={handleInputs}>
                                            <option value={values?.process_provider}>{values?.service_provider_name}</option>
                                            {service?.map((service,index)=>(
                                                <option key={index} value={service?.service_id}>{service?.service_provider_name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid provider name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Handle By</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Handler Name"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="process_handle_by"
                                            onChange={handleInputs}
                                            value={values?.process_handle_by}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid handle name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Submit</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditProcessNumber