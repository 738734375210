import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaMobile, FaRegHandPointRight, FaRegUser } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { addDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';
import { MdEmail } from 'react-icons/md';
import { Spinner } from 'react-bootstrap';


const AddDispatch = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState({});
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const subadminid = localStorage.getItem('subadmindata');

    useEffect(() => {
        if (subadminid) {
            navigate('/add-subadmin-dispatch');
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const handleInputs = async (e) => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddChanel = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsLoading(false);
        }
        else {
            const datavalue = {
                dispatch_name: values?.dispatch_name,
                dispatch_email: values?.dispatch_email,
                dispatch_mobile: values?.dispatch_mobile,
                subadmin_id: subadminid,
            };
            addDataAPI('sub-admin-add-dispatch', datavalue).then((res) => {
                setIsLoading(false);
                if (res.data.status === 1) {
                    navigate("/all-subadmin-dispatch");
                    toast.success("Dispatch has been added successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Dispatch already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUB ADMIN::ADD Dispatch</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaRegUser className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Dispatch</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleAddChanel}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationName">
                                        <Form.Label>Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaRegUser /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="dispatch_name"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationMobile">
                                        <Form.Label>Mobile</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaMobile /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter mobile number"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="dispatch_mobile"
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid mobile number.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><MdEmail /></InputGroup.Text>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email address"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="dispatch_email"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid email address.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                {isLoading ?
                                    <Button className="btn-dark" type="submit" disabled={true}>
                                        <Spinner animation="border"
                                            size="sm"
                                            role="status"
                                            variant="success"
                                        />
                                        Wait Sending...
                                    </Button>
                                    :
                                    <Button type="submit">Add</Button>
                                }
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddDispatch