import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../../components/contentwrapper/ContentWrapper'
import { Link, useParams } from 'react-router-dom'
import { fetchAllDataByIDAPI } from '../../utils/api'
import Skeleton from 'react-loading-skeleton'
import { Badge } from 'react-bootstrap'

const DisplayProcess = () => {
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [process, setProcess] = useState([]);

    useEffect(() => {
        getProcessDetails(slug);
    }, [slug]);

    const getProcessDetails = (slug) => {
        setIsLoading(true);
        fetchAllDataByIDAPI(`sub-admin-get-process-details-with-call/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setProcess(...[res.data.process]);
            }
            else {
                setIsLoading(false)
            }
        })
    }

    return (
        <div className="companypage">
            <Helmet>
                <title>SATKARTAR:SUBADMIN::PROCESS DETAILS</title>
            </Helmet>
            <ContentWrapper>
                <div className="card hrpages">
                    <div className="heading d-flex justify-content-between mb-2">
                        <h5 className="mb-0 text-uppercase">Today Process Details</h5>
                    </div>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped table-bordered table-sm" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Process Name</th>
                                    <th>Mobile</th>
                                    <th>Total Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <>
                                        <tr>
                                            {Array.from({ length: 4 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 4 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 4 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 4 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 4 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 4 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                    </>
                                    :
                                    process ?
                                        process?.map((process, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td><Link target="_blank" to={`/display-subadmin-skill-details/${process?.process_name}`} className="pcname">{process?.process_name}</Link></td>
                                                <td>{process?.process_mobile}</td>
                                                <td><Badge>{process?.totalcall}</Badge></td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={5} className="text-center">Data not founds...</td>
                                        </tr>
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Process Name</th>
                                    <th>Mobile</th>
                                    <th>Total Call</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default DisplayProcess