import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import Skeleton from 'react-loading-skeleton'
import { activeStatus, fetchAllDataAPI, removeDataFromList } from '../../utils/api'
import ContentWrapper from '../../components/contentwrapper/ContentWrapper'

const Dispatch = () => {
    const [dispatch, setDispatch] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const subadminid = localStorage.getItem('subadmindata');
    const navigate = useNavigate();

    useEffect(() => {
        if (subadminid) {
            navigate('/all-subadmin-dispatch');
            getAllDispatch()
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const getAllDispatch = () => {
        setIsLoading(true)
        fetchAllDataAPI('sub-admin-all-dispatch').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setDispatch(...[res.data.dispatchdata]);
            }
            else {
                setDispatch(false);
                setIsLoading(false);
            }
        })
    }
    const handleDispatchActive = (dispatchid) => {
        activeStatus('sub-admin-active-dispatch-status', dispatchid).then((res) => {
            if (res.data.status === 1) {
                getAllDispatch()
            }
        })
    }
    const handleDispatchInactive = (dispatchid) => {
        activeStatus('sub-admin-inactive-dispatch-status', dispatchid).then((res) => {
            if (res.data.status === 1) {
                getAllDispatch()
            }
        })
    }
    const handleDispatchRemove = (dispatchid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('sub-admin-remove-dispatch', dispatchid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllDispatch()
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="companypage">
            <Helmet>
                <title>SATKARTAR:SUBADMIN::DISPATCH</title>
            </Helmet>
            <ContentWrapper>
                <div className="card hrpages">
                    <div className="heading d-flex justify-content-between mb-2">
                        <h5 className="mb-0 text-uppercase">All Dispatch</h5>
                        <Link to="/add-subadmin-dispatch" className="btn btn-sm btn-primary pull-right"><AiOutlinePlus /> Add Dispatch</Link>
                    </div>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped table-bordered table-sm" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                    </>


                                    :
                                    dispatch ?
                                        dispatch?.map((dispatch, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{dispatch?.dispatch_name}</td>
                                                <td>{dispatch?.dispatch_email}</td>
                                                <td>{dispatch?.dispatch_mobile}</td>
                                                <td>
                                                    {(() => {
                                                        if (dispatch?.dispatch_status === "Active") {
                                                            return (
                                                                <span className="badge bg-success statustype" onClick={() => handleDispatchActive(dispatch?.dispatch_id)}>{dispatch?.dispatch_status}</span>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <span className="badge bg-danger statustype" onClick={() => handleDispatchInactive(dispatch?.dispatch_id)}>{dispatch?.dispatch_status}</span>
                                                            )
                                                        }
                                                    })()}
                                                </td>
                                                <td>
                                                    <Link to={`/edit-subadmin-dispatch/${dispatch?.dispatch_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                    <button className="btnDelete" onClick={() => handleDispatchRemove(dispatch?.dispatch_id)}><AiFillDelete /></button>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td className="text-center" colSpan={10}>Data not founds......</td>
                                        </tr>
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Dispatch